import React, { useState, useEffect } from 'react';
import { Guest, plusOneGuest } from '../types';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  // Autocomplete,
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import beetleIcon from '../../assets/BeatleMers.gif';


const RSVPForm: React.FC = () => {
  const [formData, setFormData] = useState<Guest>({
    name: '',
    plusOne: false,
    plusOneGuest: undefined,
    children: 0,
    phoneNo: '',
    attending: false,
    hotel: false,
    menu: 'Standard',
  });
  const [participation, setParticipation] = useState<boolean>(false);
  const [plusOneDisplay, setPlusOneDisplay] = useState<boolean>(false);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [rsvpError, setRsvpError] = useState<boolean>(false);

  // State for controlling the Modal
  const [openModal, setOpenModal] = useState<boolean>(false);

  // Detect scroll-to-bottom (with a threshold) and open modal once
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 50
      ) {
        setOpenModal(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handles input changes for text fields, checkboxes, etc.
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value, type } = e.target as HTMLInputElement;
    const checked = (e.target as HTMLInputElement).checked;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleHotelToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setFormData({ ...formData, hotel: value === 'Da' });
  };

  // const handleAttendingToggleChange = (
  //   event: React.MouseEvent<HTMLElement>,
  //   value: string
  // ) => {
  //   setFormData({ ...formData, attending: value === 'Da' });
  //   setParticipation(value === 'Da');
  // };

  // Handles changes for PlusOneGuest details.
  const handlePlusOneGuestChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData({
      ...formData,
      plusOneGuest: {
        ...formData.plusOneGuest,
        [name!]: value,
      } as plusOneGuest,
    });
  };

  // Handles toggling the PlusOne option.
  const handlePlusOneToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    const isPlusOne = value === 'Da';
    setFormData({
      ...formData,
      plusOne: isPlusOne,
      plusOneGuest: isPlusOne ? { name: '', menu: 'Standard' } : undefined,
    });
    setPlusOneDisplay(isPlusOne);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formDataWithId = { ...formData, id: new Date().getTime().toString() };
    fetch('https://invitebackend.azurewebsites.net/api/cosmosHandler', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formDataWithId),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .then(() => {
        setFormSuccess(true);
        setFormData({
          name: '',
          plusOne: false,
          plusOneGuest: undefined,
          children: 0,
          phoneNo: '',
          attending: false,
          hotel: false,
          menu: 'Standard',
        });
        setParticipation(false);
        setPlusOneDisplay(false);
      }).then(() => {
        setTimeout(() => {
          setOpenModal(false);
          setFormSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.error('Error:', error);
        setRsvpError(true);
      });
  };

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
            maxHeight: '90vh',
            // Custom scrollbar styles:
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#ff6f61',
              borderRadius: '4px',
            },
          }}
        >
          <Paper
            elevation={24}
            sx={{
              p: 4,
              backgroundColor: '#ffecd1',
              borderRadius: 4,
              border: '2px dashed #ff6f61',
              position: 'relative',
            }}
          >
            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={() => setOpenModal(false)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#ff6f61',
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <img
                src={beetleIcon}
                alt="RSVP Icon"
                style={{ width: 60, height: 50, marginRight: 10 }}
              />
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontFamily: 'Verdana',
                  color: '#ff6f61',
                  textShadow: '2px 2px #ffa726',
                  mb: 2,
                }}
              >
                {formSuccess ? 'Mulțumim pentru confirmare!' : 'Confirmare Participare'}
              </Typography>
            </Box>

            {formSuccess && (
              <Typography
                variant="h6"
                align="center"
                sx={{
                  fontFamily: 'Verdana',
                  color: '#4caf50',
                  textShadow: '1px 1px #388e3c',
                  mb: 2,
                }}
              >
                Confirmarea a fost trimisă cu success!
              </Typography>
            )}

            {rsvpError && (
              <Typography
                variant="h6"
                align="center"
                sx={{
                  fontFamily: 'Verdana',
                  color: '#f44336',
                  textShadow: '1px 1px #e57373',
                  mb: 2,
                }}
              >
                A intervenit o eroare la trimiterea confirmării. Vă rugăm să încercați din nou.
              </Typography>
            )}

            {!formSuccess && (
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
              >
                <TextField
                  id="name"
                  name="name"
                  label="Nume"
                  variant="outlined"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  InputProps={{ style: { fontFamily: 'Verdana' } }}
                  sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                />
                <TextField
                  id="phoneNo"
                  name="phoneNo"
                  label="Numar de telefon"
                  variant="outlined"
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                  required
                  InputProps={{ style: { fontFamily: 'Verdana' } }}
                  sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                />

                {/* <Typography
                  variant="h6"
                  align="left"
                  sx={{
                    fontFamily: 'Verdana',
                    color: '#ff6f61',
                    textShadow: '1px 1px #ffa726',
                  }}
                >
                  Vei participa?
                </Typography> */}
                {/* <ToggleButtonGroup
                  exclusive
                  value={formData.attending ? 'Da' : 'Nu'}
                  onChange={handleAttendingToggleChange}
                  aria-label="Will you attend?"
                  sx={{ alignSelf: 'center' }}
                >
                  <ToggleButton
                    value="Da"
                    aria-label="Da"
                    sx={{
                      backgroundColor: formData.attending ? '#ff6f61' : '#fff3e0',
                      borderRadius: 4,
                      fontFamily: 'Verdana',
                      color: 'black',
                      border: formData.attending
                        ? '3px solid #ff6f61'
                        : '3px solid #ccc',
                    }}
                  >
                    <Typography sx={{ fontFamily: 'Verdana' }}>Da</Typography>
                  </ToggleButton>
                  <ToggleButton
                    value="Nu"
                    aria-label="Nu"
                    sx={{
                      backgroundColor: !formData.attending ? '#ff6f61' : '#fff3e0',
                      borderRadius: 4,
                      fontFamily: 'Verdana',
                      color: 'black',
                      border: !formData.attending
                        ? '3px solid #ff6f61'
                        : '3px solid #ccc',
                    }}
                  >
                    <Typography sx={{ fontFamily: 'Verdana' }}>Nu</Typography>
                  </ToggleButton>
                </ToggleButtonGroup> */}

                {participation && (
                  <>
                    <FormControl
                      variant="outlined"
                      sx={{ backgroundColor: '#fff3e0', borderRadius: 2, p: 1 }}
                    >
                      <InputLabel id="menu-label" sx={{ fontFamily: 'Verdana' }}>
                        Tip de meniu
                      </InputLabel>
                      <Select
                        labelId="menu-label"
                        id="menu"
                        name="menu"
                        value={formData.menu}
                        onChange={(e) =>
                          handleInputChange(
                            e as React.ChangeEvent<{ name?: string; value: unknown }>
                          )
                        }
                        label="Menu Choice"
                        sx={{ fontFamily: 'Verdana' }}
                      >
                        <MenuItem value="Standard">Standard</MenuItem>
                        <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                      </Select>
                    </FormControl>

                    <Typography
                      variant="h6"
                      align="left"
                      sx={{
                        fontFamily: 'Verdana',
                        color: '#ff6f61',
                        textShadow: '1px 1px #ffa726',
                      }}
                    >
                      Vei veni insotit?
                    </Typography>
                    <ToggleButtonGroup
                      exclusive
                      value={formData.plusOne ? 'Da' : 'Nu'}
                      onChange={handlePlusOneToggleChange}
                      aria-label="Plus One"
                      sx={{ alignSelf: 'center' }}
                    >
                      <ToggleButton
                        value="Da"
                        aria-label="Da"
                        sx={{
                          backgroundColor: formData.plusOne ? '#ff6f61' : '#fff3e0',
                          borderRadius: 4,
                          fontFamily: 'Verdana',
                          color: 'black',
                          border: formData.plusOne
                            ? '3px solid #ff6f61'
                            : '3px solid #ccc',
                        }}
                      >
                        <Typography sx={{ fontFamily: 'Verdana' }}>Da</Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="Nu"
                        aria-label="Nu"
                        sx={{
                          backgroundColor: !formData.plusOne ? '#ff6f61' : '#fff3e0',
                          borderRadius: 4,
                          fontFamily: 'Verdana',
                          color: 'black',
                          border: !formData.plusOne
                            ? '3px solid #ff6f61'
                            : '3px solid #ccc',
                        }}
                      >
                        <Typography sx={{ fontFamily: 'Verdana' }}>Nu</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {plusOneDisplay && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <TextField
                          id="plusOneGuestName"
                          name="name"
                          label="Nume Invitat"
                          variant="outlined"
                          value={formData.plusOneGuest?.name}
                          onChange={handlePlusOneGuestChange}
                          required
                          InputProps={{ style: { fontFamily: 'Verdana' } }}
                          sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                        />

                        <FormControl
                          variant="outlined"
                          sx={{ backgroundColor: '#fff3e0', borderRadius: 2, p: 1 }}
                        >
                          <InputLabel id="plusOneMenu-label" sx={{ fontFamily: 'Verdana' }}>
                            Meniu Invitat
                          </InputLabel>
                          <Select
                            labelId="plusOneMenu-label"
                            id="plusOneMenu"
                            name="menu"
                            value={formData.plusOneGuest?.menu}
                            onChange={(e) =>
                              handlePlusOneGuestChange(
                                e as React.ChangeEvent<{ name?: string; value: unknown }>
                              )
                            }
                            label="Menu Choice"
                            sx={{ fontFamily: 'Verdana' }}
                          >
                            <MenuItem value="Standard">Standard</MenuItem>
                            <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          id="children"
                          name="children"
                          label="Număr copii"
                          type="number"
                          variant="outlined"
                          value={formData.children}
                          onChange={handleInputChange}
                          InputProps={{ style: { fontFamily: 'Verdana' } }}
                          sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                        />
                      </Box>
                    )}

                    <Typography
                      variant="h6"
                      align="left"
                      sx={{
                        fontFamily: 'Verdana',
                        color: '#ff6f61',
                        textShadow: '1px 1px #ffa726',
                      }}
                    >
                      Ai nevoie de o cameră la hotel?
                    </Typography>
                    <ToggleButtonGroup
                      exclusive
                      value={formData.hotel ? 'Da' : 'Nu'}
                      onChange={handleHotelToggleChange}
                      aria-label="Need a hotel room?"
                      sx={{ alignSelf: 'center' }}
                    >
                      <ToggleButton
                        value="Da"
                        aria-label="Da"
                        sx={{
                          backgroundColor: formData.hotel ? '#ff6f61' : '#fff3e0',
                          borderRadius: 4,
                          fontFamily: 'Verdana',
                          color: 'black',
                          border: formData.hotel
                            ? '3px solid #ff6f61'
                            : '3px solid #ccc',
                        }}
                      >
                        <Typography sx={{ fontFamily: 'Verdana' }}>Da</Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="Nu"
                        aria-label="Nu"
                        sx={{
                          backgroundColor: !formData.hotel ? '#ff6f61' : '#fff3e0',
                          borderRadius: 4,
                          fontFamily: 'Verdana',
                          color: 'black',
                          border: !formData.hotel
                            ? '3px solid #ff6f61'
                            : '3px solid #ccc',
                        }}
                      >
                        <Typography sx={{ fontFamily: 'Verdana' }}>Nu</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </>
                )}
                {!participation ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      sx={{
                        fontFamily: 'Verdana',
                        p: 2,
                        backgroundColor: '#ff6f61',
                        alignSelf: 'center',
                        borderRadius: 8,
                        boxShadow: '4px 4px #ffa726',
                      }}
                      onClick={() => { setParticipation(true); setFormData({ ...formData, attending: true }) }}
                    >
                      Particip
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        fontFamily: 'Verdana',
                        p: 2,
                        backgroundColor: '#ff6f61',
                        alignSelf: 'center',
                        borderRadius: 8,
                        boxShadow: '4px 4px #ffa726',
                      }}
                    >
                      Nu Particip
                    </Button>
                  </Box>
                ) :
                  (<Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      fontFamily: 'Verdana',
                      p: 2,
                      backgroundColor: '#ff6f61',
                      alignSelf: 'center',
                      borderRadius: 8,
                      boxShadow: '4px 4px #ffa726',
                    }}
                  >
                    Trimite Confirmarea
                  </Button>)
                }
              </Box>
            )}
          </Paper>
        </Box>
      </Modal>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000, // Ensure it stays on top
        }}
      >
        <Button
          onClick={() => setOpenModal(true)}
          sx={{
            backgroundColor: '#ff6f61',
            color: '#fff',
            fontFamily: 'Verdana, cursive',
            px: 4,
            py: 2,
            borderRadius: 8,
            boxShadow: '4px 4px #ffa726',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '6px 6px #ffa726',
              backgroundColor: '#ff8a80',
            },
          }}
        >
          Confirmați
        </Button>
      </Box>
    </>
  );
};

export default RSVPForm;
