import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import biserica from '../../assets/biserica.png';
import dancing from '../../assets/dancing.png';

interface EventLocationProps {
  title: string;
  time: string;
  googleMapSrc: string;
  icon: string;
}

const EventLocation: React.FC<EventLocationProps> = ({ title, time, googleMapSrc, icon }) => {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 3,
        borderRadius: 3,
        mb: 4,
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Icon - Increased Size */}
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              width: 140, // Increased size
              height: 140, // Increased size
              borderRadius: '50%',
              overflow: 'hidden',
              border: '4px solid #ff6f61',
              mx: 'auto',
            }}
          >
            <img
              src={icon}
              alt={`${title} icon`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        {/* Details */}
        <Grid item xs={12} md={9}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Verdana, cursive',
              color: '#D81B60',
              mb: 1,
              textAlign: { xs: 'center', md: 'left' },
              textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 2,
              fontWeight: 'bold',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            {time}
          </Typography>
          {/* Smaller Google Maps */}
          <Box
            sx={{
              position: 'relative',
              paddingBottom: '40%', // Reduced height from 56.25% to 40%
              height: 0,
              overflow: 'hidden',
              borderRadius: 2,
              border: '2px solid #ff6f61',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            }}
          >
            <iframe
              src={googleMapSrc}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }}
              allowFullScreen
              loading="lazy"
              title={`${title} Location`}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const LocationDetails: React.FC = () => {
  return (
    <Box id="location" sx={{ py: 8, px: 2, backgroundColor: '#f9f3dc', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          mb: 6,
          fontFamily: 'Verdana, cursive',
          color: '#D81B60',
          textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
        }}
      >
        Unde?
      </Typography>
      <EventLocation
        title="Ceremonia religioasā"
        time="Sâmbātā, 31 Mai, 2025, 17:00"
        googleMapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.221481417222!2d24.992110971488334!3d44.10739663711288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40adae3fb631d68f%3A0xb67446182e59f00a!2sCathedral%20%22St.%20Teodor%20Tiron%22%20Rosiori%20de%20Vede!5e0!3m2!1sen!2sro!4v1739740396241!5m2!1sen!2sro"
        icon={biserica}
      />
      <EventLocation
        title="Petrecerea"
        time="Sâmbātā, 31 Mai, 2025, 20:00"
        googleMapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2864.4987276601523!2d24.984166776665305!3d44.114334271084125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40adae446d25fcc5%3A0x1f585d408e27f609!2sEntourage%20Club!5e0!3m2!1sen!2sro!4v1739740249272!5m2!1sen!2sro"
        icon={dancing}
      />
    </Box>
  );
};

export default LocationDetails;
