import React from 'react';
import styles from './CarAnimation.module.scss';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { AppContext } from './AppContext';


const CarAnimation: React.FC = () => {
  const context = React.useContext(AppContext);

  return (
    <div className={styles.container}>
      {/* Round Glassmorphism Button with Play Icon */}
      <Button
        variant="contained"
        sx={{
          position: 'absolute',
          zIndex: 10,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.3)',
          color: '#fff',
          boxShadow: 'none',
          borderRadius: '50%',
          width: 128,
          height: 128,
          minWidth: 'auto',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            boxShadow: 'none',
          },
        }}
        onClick={() => { context?.setGlobalState({ intro: false }); }}
      >
        <PlayArrowIcon fontSize="large" />
      </Button>
      {/* SKY */}
      <div className={styles.sky}>
        <div className={styles.sun}>
          <div className={styles['sun-rays']}>
            {[...Array(12)].map((_, i) => (
              <div key={`ray-${i}`} className={styles['sun-ray']}></div>
            ))}
          </div>
        </div>
        <div className={styles.cloud} />
      </div>
      {/* GROUND */}
      <div className={styles.ground}></div>
      <div className={styles['ground-four']}></div>

      {/* STREET LIGHT */}
      <div className={styles['street-light']}>
        <div className={styles['street-light-poll']}></div>
        <div className={styles.light}></div>
        <div className={styles['street-light-top']}></div>
      </div>

      {/* TREE */}
      <div className={styles.tree}>
        <div className={styles['tree-trunk']}></div>
        <div className={styles['leaves_five']}></div>
        <div className={styles['leaves_three']}></div>
        <div className={styles['leaves_four']}></div>
        <div className={styles.leaves}></div>
        <div className={styles['leaves_two']}></div>
        <div className={styles.berry}></div>
        <div className={styles['berry-two']}></div>
        <div className={styles['berry-three']}></div>
        <div className={styles['berry-four']}></div>
        <div className={styles['berry-five']}></div>
        <div className={styles['berry-six']}></div>
      </div>
      <div className={styles.road}></div>

      {/* CAR */}
      <div className={styles['car-horizontal']}>
        <div className={styles['car-container']}>
          <div className={styles['car-font-bump']}></div>
          <div className={styles['car-body']}></div>
          <div className={styles['car-hood']}></div>
          <div className={styles['car-front-window']}></div>
          <div className={styles['car-font-window-mask']}></div>
          <div className={styles['car-window-divider']}></div>
          <div className={`${styles['car-window-divider']} ${styles['divider-two']}`}></div>
          <div className={styles['car-back-window-divider']}></div>
          <div className={styles['front-seat']}></div>
          <div className={styles['girl-driver']}>
            <div className={styles.hair}></div>
            <div className={styles['long-hair']}></div>
            <div className={styles['long-hair-layer']}></div>
            <div className={styles.head}></div>
            <div className={styles.body}></div>
            <div className={styles['arm-left']}></div>
            <div className={styles['arm-right']}></div>
          </div>
          <div className={styles['steering-wheel']}></div>
          <div className={styles['rear-view-mirror']}></div>
          <div className={styles['car-door']}></div>
          <div className={`${styles['car-door']} ${styles['car-door-two']}`}></div>
          <div className={`${styles['car-door']} ${styles['car-door-three']}`}></div>
          <div className={styles['car-headlight']}></div>
          <div className={styles['car-exhaust']}></div>
          <div className={styles['car-mirror']}></div>
          <div className={styles['gas-pump']}></div>
          <div className={styles['gas-pump-lines']}></div>
          <div className={styles['tail-light']}></div>
          <div className={styles['door-handle']}></div>
          <div className={styles['hood-door']}>
            <div className={styles['hood-door-line']}></div>
            <div className={styles['hood-door-line']}></div>
            <div className={styles['hood-door-window']}></div>
          </div>
          <div className={styles['trunk-tire']}></div>
          <div className={styles['front-line']}></div>
          <div className={styles['front-line-two']}></div>
          <div className={styles['front-line-three']}></div>

          {/* CAR RACK */}
          <div className={styles.case}></div>
          <div className={styles.casetwo}></div>
          <div className={styles['car-rack']}></div>
          <div className={`${styles['car-rack']} ${styles['rack-line-left']}`}></div>
          <div className={`${styles['car-rack']} ${styles['rack-line-center']}`}></div>
          <div className={`${styles['car-rack']} ${styles['rack-line-right']}`}></div>
          <div className={`${styles['car-rack']} ${styles['rack-level-two']}`}></div>
          {/* CAR WHEELS */}
          <div className={styles['front-wheel']}>
            <div className={styles.rim}></div>
            <div className={styles['tire-line']}></div>
          </div>
          <div className={`${styles['front-wheel']} ${styles['wheel-two']}`}>
            <div className={styles.rim}></div>
            <div className={styles['tire-line']}></div>
          </div>
          {/* SMOKE CONTAINER */}
          <div className={styles['smoke-container']}>
            <div className={styles.smoke}></div>
            <div className={styles.smoke}></div>
            <div className={styles.smoke}></div>
            <div className={styles.smoke}></div>
            <div className={styles.smoke}></div>
            <div className={styles.smoke}></div>
          </div>
        </div>
      </div>


      {/* SPEED LINES */}
      <div className={styles['speed-lines']}>
        <div className={styles['speed-line-one']}></div>
        <div className={styles['speed-line-two']}></div>
        <div className={styles['speed-line-three']}></div>
        <div className={styles['speed-line-four']}></div>
      </div>



      {/* ADDITIONAL GROUND */}
      <div className={styles['ground-two']}></div>
      <div className={styles['ground-three']}></div>
      <div className={styles.sideRoad}>
        {/* Grass */}
        {[...Array(200)].map((_, i) => (
          <div
            key={`grass-${i}`}
            className={styles.grass}
            style={{ left: `${Math.random() * 100}%`, bottom: `${Math.random() * 30}vh`, zIndex: 3 }}
          ></div>
        ))}

        <div className={styles['flower-container']}></div>


        {/* Rocks */}
        {[...Array(10)].map((_, i) => (
          <div
            key={`rock-${i}`}
            className={styles.rock}
            style={{ left: `${Math.random() * 100}%`, bottom: `${Math.random() * 20}vh` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default CarAnimation;
