import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface GlobalState {
  intro: boolean;
}

interface AppContextProps {
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

// Initialize context with `undefined` to enforce usage within a provider.
export const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [globalState, setGlobalState] = useState<GlobalState>({ intro: true });
  return (
    <AppContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </AppContext.Provider>
  );
};

