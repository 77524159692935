import React from 'react';
import { Box, Typography, Link, Paper, Container } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import backgroundImg from '../../assets/parchment.png';

const ContactSection: React.FC = () => {
  return (
    <Box
      id="contact"
      sx={{
        position: 'relative',
        py: 8,
        backgroundColor: '#f9f3dc',
        overflow: 'hidden', // Ensures background stays inside the section
      }}
    >
      {/* Blurred Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(1px)', // Apply blur effect
          opacity: 0.3, // Make it more subtle
          zIndex: 1,
        }}
      />

      {/* Content */}
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 2, textAlign: 'center' }}>
        <Paper
          elevation={4}
          sx={{
            p: 4,
            backgroundColor: '#ffecd1',
            borderRadius: 4,
            border: '2px dashed #ff6f61',
            maxWidth: 600,
            mx: 'auto',
          }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{ fontFamily: 'Verdana', color: '#ff6f61', mb: 3 }}
          >
            Contact
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
            <PhoneIcon sx={{ color: '#ff6f61', mr: 1 }} />
            <Typography variant="body1" sx={{ fontFamily: 'Verdana', color: 'black' }}>
              <Link
                href="tel:+40769454502"
                underline="hover"
                sx={{ color: '#ff6f61', fontFamily: 'Verdana' }}
              >
                +40 769 454 502
              </Link>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
            <EmailIcon sx={{ color: '#ff6f61', mr: 1 }} />
            <Typography variant="body1" sx={{ fontFamily: 'Verdana', color: 'black' }}>
              <Link
                href="mailto:ioanacatalinafierascu@yahoo.ro"
                underline="hover"
                sx={{ color: '#ff6f61', fontFamily: 'Verdana' }}
              >
                ioanacatalinafierascu@yahoo.ro
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactSection;
