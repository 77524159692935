import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../AppContext';

const Music = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const context = useContext(AppContext);
  const globalState = context?.globalState;


  useEffect(() => {
    if (globalState?.intro === false) {
      playAudio();
    }
  }, [globalState]);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  return (
    <div>
      <audio
        ref={audioRef}
        src="https://archive.org/download/BrunoMarsMarryYou/Bruno%20Mars%20-%20Marry%20You.mp3"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default Music;
