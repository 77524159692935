import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import image1 from "../../assets/image1.jpg";
import image2 from "../../assets/image2.jpg";
import image3 from "../../assets/image3.jpeg";
import image4 from "../../assets/image4.jpg";
import image5 from "../../assets/animals.png";
import backround from "../../assets/texture.png";

const events = [
  {
    title: "Capitolul 1:",
    description: "Era o dată o fată și cățelușa ei, convinse că bărbații sunt... supraestimați.",
    image: image2,
  },
  {
    title: "Capitolul 2:",
    description: "A apărut el – dovada vie că soarta știe ce face uneori... și a reușit să le cucerească pe amândouă.",
    image: image4,
  },
  {
    title: "Capitolul 3:",
    description: "Cu o pisică în plus și un cămin complet, iubirea lor chiar devenise acasă.",
    image: image5,
  },
  {
    title: "Capitolul 4:",
    description: "Pe un peron magic, el a făcut vrăjitoria supremă – a cerut-o de soție, iar ea a spus 'DA'.",
    image: image1,
  },
  {
    title: "Capitolul 5:",
    description: "Și-au spus 'DA' – începutul oficial al poveștii lor pentru totdeauna",
    image: image3,
  }
];

const LoveStoryTimeline: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "relative",
        py: 4,
        backgroundColor: "#f9f3dc",
        overflow: "hidden", // Ensures background stays within bounds
      }}
    >
      {/* Background Overlay to fade the image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backround})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.3, // This only fades the background, not content
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontFamily: "Verdana, cursive",
            color: "#D81B60",
            textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            mb: 3,
          }}
        >
          De ce? 💕
        </Typography>

        {/*
        Switch to 'left' on small screens (so everything lines up nicely)
        and use 'alternate' for larger screens.
      */}
        <Timeline position={"alternate"}>
          {events.map((event, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  variant={index % 2 === 0 ? "filled" : "outlined"}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#ff4081" : "#ff9800",
                    border: "3px dashed white",
                    width: 20,
                    height: 20,
                  }}
                />
                {index < events.length - 1 && (
                  <TimelineConnector
                    sx={{
                      backgroundColor: "#ff9800",
                      // Shorter connector on small screens
                      height: isSmallScreen ? 50 : 100,
                    }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Card
                  sx={{
                    // Use responsive breakpoints to switch layout
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: index % 2 === 0 ? "row" : "row-reverse",
                    },
                    alignItems: "center",
                    boxShadow: 6,
                    borderRadius: 3,
                    overflow: "hidden",
                    backgroundColor: "#fffde7",
                    // Let it shrink on mobile and cap the width on larger screens
                    width: "100%",
                    maxWidth: 600,
                    mx: "auto",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={event.image}
                    alt={event.title}
                    sx={{
                      width: { xs: "100%", sm: "50%" },
                      height: { xs: "auto", sm: 250 },
                      objectFit: "cover",
                    }}
                  />
                  <CardContent
                    sx={{
                      width: { xs: "100%", sm: "50%" },
                      textAlign: "center",
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Verdana, cursive",
                        color: "#D81B60",
                      }}
                    >
                      {event.title}
                    </Typography>
                    <Typography variant="body1">{event.description}</Typography>
                  </CardContent>
                </Card>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Box>
  );
};

export default LoveStoryTimeline;
