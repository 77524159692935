import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './FlipWatchDigit.module.scss';

interface PulseDigitProps {
  value: number | string;
}

const PulseDigit: React.FC<PulseDigitProps> = ({ value }) => {
  const [pulse, setPulse] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (value !== displayValue) {
      // Trigger pulse class
      setPulse(true);
      // Update the displayed value after the pulse finishes
      const timer = setTimeout(() => {
        setDisplayValue(value);
        setPulse(false);
      }, 300); // match with CSS animation duration
      return () => clearTimeout(timer);
    }
  }, [value, displayValue]);

  return (
    <Box className={`${styles.pulseWrapper} ${pulse ? styles.pulsing : ''}`}>
      <Typography variant="h4">{displayValue}</Typography>
    </Box>
  );
};

export default PulseDigit;
