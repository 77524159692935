import React, { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import Hero from './innerComponents/Hero';
import About from './innerComponents/About';
import RSVP from './innerComponents/RSVP';
import Contact from './innerComponents/Contact';
import Music from './innerComponents/Music';
import LoveStoryTimeline from './innerComponents/Timeline';
import LocationDetails from './innerComponents/Event';
import styles from './FullPage.module.scss';

const WeddingInvite: React.FC = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(`.${styles['scroll-container']} > div`);

    const handleScroll = () => {
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          section.classList.add('show'); // Add the 'show' class when in view
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger on load

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.background}>
      <CssBaseline />
      <div className={styles['scroll-container']}>
        <Music />
        <Hero />
        <About />
        <LoveStoryTimeline />
        <LocationDetails />
        <Contact />
        <RSVP />
      </div>
    </div>
  );
};

export default WeddingInvite;
