import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Fireworks } from '@fireworks-js/react';
import background from '../../assets/texture.png';
import PulseDigit from './Flip';

const Hero: React.FC = () => {
  interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }

  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date('2025-05-31T17:00:00') - +new Date();
    let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <Box
      id="hero"
      sx={{
        position: 'relative',
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        pt: '64px', // offset for navbar height
      }}
    >
      {/* Dark overlay for readability */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      />

      {/* Main content */}
      <Box sx={{ position: 'relative', zIndex: 2, color: '#fff' }}>
        {/* Title with integrated fireworks effect */}
        <Box sx={{ position: 'relative', display: 'inline-block', mb: 2 }}>
          <Typography
            variant="h1"
            sx={{
              fontFamily: '"Dancing Script", "Brush Script MT", "Lucida Handwriting", cursive',
              textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
              position: 'relative',
              zIndex: 2,
              px: 2,
            }}
          >
            Ne căsătorim!
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              pointerEvents: 'none',
            }}
          >
            <Fireworks
              options={{
                rocketsPoint: { min: 100, max: 100 },
                hue: { min: 100, max: 360 },
                delay: { min: 50, max: 300 },
              }}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>

        <Typography
          variant="h2"
          sx={{ mb: 4, textShadow: '2px 2px 4px rgba(0,0,0,0.7)', px: 2, }}
        >
          Ioana &amp; George
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: '"Dancing Script", "Brush Script MT", "Lucida Handwriting", cursive', textShadow: '2px 2px 4px rgba(0,0,0,0.7)' }}>
            31 Mai 2025
          </Typography>
        </Box>
        {/* Countdown digits with labels */}
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >

          {/* Days */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PulseDigit value={timeLeft.days} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Zile
            </Typography>
          </Box>

          {/* Hours */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PulseDigit value={timeLeft.hours} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Ore
            </Typography>
          </Box>

          {/* Minutes */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PulseDigit value={timeLeft.minutes} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Minute
            </Typography>
          </Box>

          {/* Seconds */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PulseDigit value={timeLeft.seconds} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Secunde
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
