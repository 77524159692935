import React from 'react';
import { Container, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import backgroundImg from '../../assets/brideandgroom.png'; // adjust path as needed

const About: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      id="about"
      maxWidth={isMobile ? "md" : "xl"}
      sx={{
        py: { xs: 6, md: 8 },
        px: { xs: 2, md: 4 },
        position: 'relative',
        borderRadius: 2,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',

      }}
    >
      {/* Overlay for readability */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(253, 246, 227, 0.85)', // semi-transparent overlay
          zIndex: 1,
          borderRadius: 2,
        }}
      />
      {/* Content placed above the overlay */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          align="center"
          sx={{
            mb: 3,
            fontFamily: 'Verdana, cursive',
            color: '#D81B60',
            textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
            display: 'inline-block',
            px: 2,
            py: 1,
            borderRadius: 1,
          }}
        >
          Ce?
        </Typography>
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Verdana, cursive',
              fontSize: { xs: '1rem', sm: '1.1rem' },
              color: '#555',
              lineHeight: 1.6,
              display: 'inline-block',
              maxWidth: '70%',
              px: { xs: 2, sm: 3 },
              py: { xs: 1, sm: 2 },
              borderRadius: 1,
              textAlign: 'justify',
            }}
          >
            Hei, prieteni și familie,

            Noi, Ioana și George, am decis să facem pasul cel mare! Nu de unii singuri, bineînțeles – îi avem alături pe nașii noștri de nădejde, Irina și Radu, care au acceptat provocarea de a ne ține în frâu.
            Și, desigur, părinții noștri dragi, care ne-au susținut la fiecare pas (și încă încearcă să înțeleagă cum de ne-au crescut așa bine), vor fi acolo cu emoție și zâmbete largi.
            Vă așteptăm să sărbătorim împreună cea mai importantă zi din viața noastră, cu multe râsete, voie bună și, evident, ceva dans de neuitat!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
