import { useContext } from 'react';
import CarAnimation from './components/animation';
import { AppContext } from './components/AppContext';
import WeddingInvite from './components/FullPage';
// import RSVPForm from './components/RSVP';

function App() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  const { globalState } = context;
  return (
      <div>
        {globalState.intro === true ? <CarAnimation /> : <WeddingInvite />}
        {/* <RSVPForm /> */}
      </div>
  );
}

export default App;
